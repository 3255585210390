<template>
  <div>
    <CRow>
      <CCol sm="12">
        <TicketDetails :public="true" :validation="false"/>
      </CCol>
    </CRow>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template v-for="(toast, key) in toasts">
        <CToast :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import TicketDetails from '../../components/tickets/TicketDetails.vue'
import tickets from '../../services/tickets';
import store from '../../store'
import Vue from 'vue';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import ws from '../../services/login';
import axios from 'axios'

export default {
  name: 'PublicTicket',
  components: { TicketDetails, Loading },
  data: function () {
		return {
        code: 'active',
        title: 'Reportes Activos',
        tickets: []
    }
  },
  mounted: async function() {
    this.loading();

    let phone = await this.$route.params.phone;
    let code = await this.$route.params.code;

    let token = localStorage.getItem("token");

    if(token != null){
      let response = await ws.validate({token: localStorage.getItem("token")});

      if(response.type == "success"){
        window.location.href = "/#/tickets/"+code+"/view"; return;
      }
    }
    else{
      if(phone !== undefined && phone != ''){
        let response = await ws.validatePhone({code: code, phone: phone});

        if(response.type == "success"){
          let data = response.data;

          localStorage.setItem("latitude", data.account.latitude);
          localStorage.setItem("longitude", data.account.longitude);
          localStorage.setItem("domain", data.account.domain);
          localStorage.setItem("account",JSON.stringify(data.account));
          localStorage.setItem("user",JSON.stringify(data));
          localStorage.setItem("permissions", data.role.permissions);
          localStorage.setItem("token", data.api_token);

          axios.defaults.headers.common['domain'] = data.account.domain;
          axios.defaults.headers.common['api-token'] = data.api_token;

          window.location.href = "/#/tickets/"+code+"/view"; return;
        }
        else{
          window.location.href = "/#/reporte/"+code; return;
        }
      }
    }
    
    this.loaded();
  },
  methods: {
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    toasts () {
      return store.state.toasts;
    },
    visible() {
      return store.state.isLoading;
    }
  },
}
</script>
